import React, { useState } from "react";
import Field from '../custom/Field'
import RadioGroup from '../custom/RadioGroup';
import FileList from '../custom/FileList';

export default function TogInsuranceForm({ togFormData, validCheck, radioRequired, updateTogFiles, updateTogFormData }) {

    return (
        <>
            <div>
                <label className="input-label">
                    <span className="required"> Vennligst velg tog selskap</span>
                    <select id="trainCompany" name="trainCompany" required onChange={(event) => { updateTogFormData(event.target.name, event.target.value, "Tog selskap") }}>
                        <option value="">--- Velg tog selskap ---</option>
                        <option value="vy">VY</option>
                        <option value="Flytoget">Flytoget</option>
                        <option value="Go-Head-Nordic">Go- Head Nordic</option>
                        <option value="Sj">Sj</option>
                    </select>
                </label>
                {validCheck && ((!togFormData["trainCompany"]) || togFormData["trainCompany"] === "") && <p className="error-message">Dette feltet er obligatorisk</p>}
            </div>

            <Field item={{ formName: "togForm", name: "idNumber", type: "text", value: togFormData["idNumber"], isValidCheck: validCheck, label: "Id nummer", placeholder: "Id nummer" }} updateTogFormData={updateTogFormData} />
            <Field item={{ formName: "togForm", name: "togType", type: "text", value: togFormData["togType"], isValidCheck: validCheck, label: "Tog type", placeholder: "Tog type" }} updateTogFormData={updateTogFormData} />
            <Field item={{ formName: "togForm", name: "injuryDate", type: "date", value: togFormData["injuryDate"], required: true, isValidCheck: validCheck, label: "Skadedato", placeholder: "Skadedato" }} updateTogFormData={updateTogFormData} />
            <Field item={{ formName: "togForm", name: "injuryPlace", type: "text", value: togFormData["injuryPlace"], required: true, isValidCheck: validCheck, label: "Skadested", placeholder: "Skadested" }} updateTogFormData={updateTogFormData} />
            <Field item={{ formName: "togForm", name: "injuryType", type: "text", value: togFormData["injuryType"], required: true, isValidCheck: validCheck, label: "Skadehendelse", placeholder: "Skadehendelse" }} updateTogFormData={updateTogFormData} />
            <Field item={{ formName: "togForm", name: "injuryDescroption", type: "text", value: togFormData["injuryDescroption"], required: true, isValidCheck: validCheck, label: "Årsak og omfang?", placeholder: "Årsak og omfang" }} isTextarea={true} updateTogFormData={updateTogFormData} />
            <Field item={{ formName: "togForm", name: "employee", type: "text", value: togFormData["employee"], required: true, isValidCheck: validCheck, label: "Personskade hos ansatte eller andre", placeholder: "Personskade hos ansatte eller andre" }} updateTogFormData={updateTogFormData} />

            <RadioGroup item={{ value: togFormData["togThirdPerson"], name: "togThirdPerson", label: "Er tredjepart involvert?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} updateTogFormData={updateTogFormData} />
            {togFormData["togThirdPerson"] === "Ja" && <div className='indentBlock'>
                <Field item={{ name: "togThirdPersonName", value: togFormData["togThirdPersonName"], isValidCheck: validCheck, label: "Hvem var det?", value: togFormData["togThirdPersonName"], required: true }} updateTogFormData={updateTogFormData} />
            </div>
            }

            <RadioGroup item={{ value: togFormData["togPolitiet"], name: "togPolitiet", label: "Er politiet informert?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} updateTogFormData={updateTogFormData} />
            {togFormData["togPolitiet"] === "Ja" && <div className='indentBlock'>
                <Field item={{ name: "togPolitikammer", value: togFormData["togPolitikammer"], isValidCheck: validCheck, label: "Hvilket politikammer?", placeholder: "Hvilket politikammer", value: togFormData["togPolitikammer"], required: true }} updateTogFormData={updateTogFormData} />
            </div>
            }

            <Field item={{ name: "togContactEmail", value: togFormData["togContactEmail"], type: "email", isValidCheck: validCheck, label: "Kontaktperson epost", placeholder: "Kontaktperson epost", value: togFormData["togContactEmail"], required: true }} updateTogFormData={updateTogFormData} />
            <Field item={{ name: "togContactMobil", value: togFormData["togContactMobil"], type: "text", isValidCheck: validCheck, label: "Kontaktperson mobil", placeholder: "Kontaktperson mobil", value: togFormData["togContactMobil"], required: true }} updateTogFormData={updateTogFormData} />
            <p>Trykk for å velge filer eller dra dem til filknappen</p>
            <FileList item={{ formName: "togForm", text: "Velg fil", name: "attachments", required: false, multiple: true }} updateTogFiles={updateTogFiles} />
            <br />
        </>
    )
}