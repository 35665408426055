import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getRedirectUrl } from '../js/api-service';
import { InfoContext } from './context';
import { getSmsCode, CheckSmsCode } from '../js/api-service';


export default function DefaultHome() {

    const { updateFail, smsConfirm, setSmsConfirm, updateSessionStorage } = useContext(InfoContext)
    const [email, setEmail] = useState("");
    const [claimNumber, setClaimNumber] = useState("");
    const [smsCode, setSmsCode] = useState("");
    const [smsResult, setSmsResult] = useState();
    const [failMessage, setFailMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    const onLoginClick = async (event) => {

        event.preventDefault();
        const selectedVlue = event.target.value;
        if (selectedVlue) {
            updateSessionStorage();
            try {
                const data = await getRedirectUrl(selectedVlue);
                if (data.redirectUri) {
                    window.location.href = data.redirectUri;
                }
            } catch (error) {
                updateFail(true);
            }
        }
    }

    const handleClaimSubmit = async (event) => {
        event.preventDefault();
        if (claimNumber === "" || email === "") return;
        setLoading(true);
        updateSessionStorage();
        try {
            const result = await getSmsCode(claimNumber, email);
            if (!result) {
                setFailMessage(true);
            } else {
                setSmsConfirm(true);
                setSmsResult(result);
                setFailMessage(false);
            }
        } catch {
            setFailMessage(true);
        }
        setLoading(false);
    }

    const handleSmsCheck = async (event) => {

        event.preventDefault();
        if (smsCode === "") return;
        setLoading(true);
        try {
            const result = await CheckSmsCode(smsCode, smsResult?.currentTime);
            if (!result) {
                setFailMessage(true);
            } else {
                setSmsConfirm(false);
                setFailMessage(false);
                navigate(`/?Session=${result}`, { state: { session: result } });
            }
        } catch {
            setFailMessage(true);
        }
        setLoading(false);
    }

    return (
        <>
            {loading && <div className="loading" aria-label="Vennligst vente..."></div>}
            {!loading && <>
                <h2 className="large-header align-center portal-title">Velkommen til Crawford portal</h2>
                <div className="home-container smslogin">
                    {!smsConfirm ? <>
                        <p>Velg metode for innlogging: </p>
                        {/* <button className="login-button" type="submit" onClick={onLoginClick} >Login</button> */}
                        <button role="link" className="loginbutton loginbutton__bankid" type="button" onClick={onLoginClick} value="urn:grn:authn:no:bankid" >Login med bankid eller bankid på mobil (Norway)</button>
                        <button role="link" className="loginbutton loginbutton__mitid" type="button" onClick={onLoginClick} value="urn:grn:authn:dk:mitid:substantial" >Login med mitid (Denmark)</button>
                        <button role="link" className="loginbutton loginbutton__sebankid" type="button" onClick={onLoginClick} value="urn:grn:authn:se:bankid:another-device:qr" >Login med bankid (Sweden) </button>
                        <br />
                        <p>Har du mottatt melding om å logge på med kode på SMS, oppgi ditt saksnummer og epost nedenfor:</p>
                        <form name='claimForm' onSubmit={(event) => { handleClaimSubmit(event) }}>
                            <input type='text' name='claimNumber' placeholder='Saksnummer' aria-label='Saksnummer' required onChange={(event) => setClaimNumber(event.target.value.trim())} />
                            <input type='email' name='email' placeholder='Epost' aria-label='Epost' required onChange={(event) => setEmail(event.target.value.trim())} />
                            <button type='submit' disabled={claimNumber === "" || email === ""} >Submit</button>
                            {failMessage && <p className='error-message'>Ingen sak ble funnet med saksnummer og epost som du har sendt!</p>}
                        </form>
                    </>
                        :
                        <>
                            <p>Vennligst skriv inn koden vi har sendt til {smsResult?.mobile}</p>
                            <form name='smsForm' onSubmit={(event) => { handleSmsCheck(event) }}>
                                <input autoFocus type='text' name='smsCode' placeholder='Sms code' aria-label='Sms code' pattern="[0-9]{6}$" required onChange={(event) => setSmsCode(event.target.value.trim())} inputMode='numeric' />
                                <button type='submit' disabled={smsCode === "" || smsCode.length !== 6} >Send inn</button>
                                {failMessage && <p className='error-message'>Det oppsto en feil! Vennligst prøv igjen eller kontakt oss.</p>}
                            </form>
                        </>
                    }
                </div>
                <br />
                {!smsConfirm && <p className='lead align-center'>Har du ikke saksnummer, kan du benytte vårt generelle <Link className='semiBold color-blue' to="/kontakt">Kontaktskjema</Link></p>}
            </>
            }
        </>
    )
}
