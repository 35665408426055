export async function getRedirectUrl(code) {

    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/redirect`, {
        method: 'POST',
        body: JSON.stringify({ IdentytyType: code }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(data => data)

    return result;
}
export async function getList(session) {

    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/getlist`, {
        method: 'POST',
        body: JSON.stringify({ Token: session }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(data => data)

    return result;
}

export async function getParterList(Token) {

    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/parterlist`, {
        method: 'POST',
        body: JSON.stringify({ Token }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(data => data)

    return result;
}

export async function postMaildata(Token, maildata) {

    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/postmaildata`, {
        method: 'POST',
        body: JSON.stringify({ Token: Token, maildata: maildata }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(data => data)

    return result;
}

export async function postAttachment(token, attachment, envelopId) {

    const encodeToken = encodeURI(token);
    const formData = new FormData();
    formData.append("attachment", attachment, attachment.name);

    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/postattachment/${encodeURIComponent(encodeToken)}/${encodeURIComponent(envelopId)}`, {
        method: 'POST',
        body: formData

    }).then(response => response.text()).then(data => data)

    return result;
}

export async function deleteToken(token) {

    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/logout`, {
        method: 'POST',
        body: JSON.stringify({ Token: token }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(data => data)

    return result;
}

export async function getSmsCode(claimNumber, email) {

    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/getsms`, {
        method: 'POST',
        body: JSON.stringify({ claimNumber, email }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(data => data)

    return result;
}

export async function getTogSmsCode(email) {

    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/gettogsms`, {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(data => data)

    return result;
}

export async function CheckSmsCode(smsCode, currentTime) {

    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/checksmscode`, {
        method: 'POST',
        body: JSON.stringify({ smsCode, currentTime }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.text()).then(data => data)

    return result;
}

export async function updateMaildata(token, id) {
    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/updatemaildata`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token, id })
    }).then(response => response.text()).then(data => data)

    return result;
}

export async function getToken() {
    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.text()).then(data => data)

    return result;
}

export async function postContactForm(contactData, token) {
    const body = { contactData, token }
    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/contact`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(response => response.text()).then(data => data)

    return result;
}

export async function postFullmakt(Token, pdfInput) {

    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/postfullmakt`, {
        method: 'POST',
        body: JSON.stringify({ Token, pdfInput }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.text()).then(data => data)

    return result;
}

export async function generatePdf(token, body) {

    // console.log(body);
    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/signdocument/${encodeURIComponent(token)}`, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(response => response.json()).then(data => { return data })
    return result;
}