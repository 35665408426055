import React, { useState } from "react";

export default function TogSms({ updateTogFormData, validCheck, mobileNumber }) {

    return (
        <>
            <label className="input-label">
                <span className="required">Venligst oppgi kode sendt på sms til {mobileNumber}</span>
                <input autoFocus type='text' name='togSmsCode' placeholder='Sms Koden (6 siffer)' aria-label='Sms koden' pattern="[0-9]{6}$" required onChange={(event) => { if (event.target.value.trim().length === 6) updateTogFormData(event.target.name, event.target.value.trim()) }} inputMode='numeric' />
            </label>
            {validCheck && <p className="error-message" >Koden er ikke riktig</p>}
        </>
    )
}