import React, { useState, useRef, useEffect } from "react";
import TogEmail from './TogEmail'
import TogSms from './TogSms'
import TogInsuranceForm from './TogInsuranceForm'
import Spinner from '../../components/Spinner'
import { getTogSmsCode, CheckSmsCode, postMaildata, postAttachment } from '../../js/api-service';
import { ignoreTimeOffset } from '../../js/utility'

export default function TogHomePage() {

    const togForm = useRef();
    const [validCheck, setValidCheck] = useState(false)
    const [stage, setStage] = useState(1)
    const [mobileNumber, setMobileNumber] = useState()
    const [currentTime, setCurrentTime] = useState()
    const [token, setToken] = useState()
    const [error, setError] = useState("")
    const [togFormData, setTogFormData] = useState({})
    const [togFormQuestions, setTogFormQuestions] = useState({})
    const [togFiles, setTogFiles] = useState({})
    const [radioRequired, setRadioRequired] = useState("pending");
    const [isSpinner, setIsSpinner] = useState(false);

    useEffect(() => {
        setValidCheck(false)
        setRadioRequired("pending")
        setIsSpinner(false)
        setError("")
    }, [stage])

    const handleTogFormSubmit = async (event) => {
        event.preventDefault();
        if (stage !== 3) return;
        if (!togForm.current.checkValidity()) {
            togForm.current.querySelector(':invalid').focus();
            setValidCheck(true);
            setRadioRequired(true);
            return;
        } else {
            setIsSpinner(true)
            const envelopId = `${token.split("-")[0]}${new Date().valueOf()}`;
            const maildata = {
                DelegateTo: 1,
                DateInserted: ignoreTimeOffset(),
                DateReceived: ignoreTimeOffset(),
                DateSent: ignoreTimeOffset(),
                MailFrom: `${togFormData["togEmail"]}`,
                MailTo: "firmapost@crawco.no",
                Mailsubject: `Ny skade Norske tog Forsikring AS`,
                DelegationInfo: `Ny skade Norske tog Forsikring AS`,
                Body: JSON.stringify(togFormQuestions),
                Direction: 'In',
                DocType: 'Webskjema',
                SystemId: "LCS",
                BodyFormat: 1,
                CountAttachment: togFiles ? togFiles?.length : 0,
                InternalComment: envelopId,
                Claimid: "",
                Client: "Norske tog Forsikring AS"
            };
            try {
                const maildataResult = await postMaildata(token, maildata);
                if (maildataResult.toString() !== "Maildata Added") throw new Error();
            } catch {
                setStage(5)
                return
            }
            if (togFiles && togFiles?.length > 0) {
                let files = Array.from(togFiles);
                for (let index = 0; index < files.length; index++) {
                    const fileElement = files[index];
                    try {
                        const attachmentResult = await postAttachment(token, fileElement, envelopId);
                    } catch {
                        setError("Feil på opplasting av vedlegg")
                        setStage(5)
                        return
                    }
                }
            }
            setStage(4)
            setIsSpinner(false)
        }
    }
    const handleNext = async (event) => {
        if (!togForm.current.checkValidity()) {
            setValidCheck(true);
            setRadioRequired(true);
            return;
        }
        setIsSpinner(true)
        event.preventDefault();
        setValidCheck(true)
        setRadioRequired("submit")

        if (stage === 1) {
            try {
                const smsResult = await getTogSmsCode(togFormData["togEmail"])
                setMobileNumber(smsResult?.mobile)
                setCurrentTime(smsResult?.currentTime)
                setStage(prevCounter => prevCounter + 1)
            } catch {
                setError("Vi har ikke funnet noen match for denne epost!")
            }
        } else if (stage === 2) {
            try {
                const smsToken = await CheckSmsCode(togFormData["togSmsCode"], currentTime)
                if (smsToken) {
                    setToken(smsToken)
                    setStage(prevCounter => prevCounter + 1)
                }
            } catch {
                setError("Sms koden er ikke riktig!")
            }
        } else {
            setStage(1)
        }
        setIsSpinner(false)
    }

    const updateTogFormData = (name, value, label = "") => {
        let formData = { ...togFormData, [name]: value }
        setTogFormData(formData);
        if (label !== "") {
            let formQuestions = { ...togFormQuestions, [label]: value }
            setTogFormQuestions(formQuestions);
        }
    }
    const updateTogFiles = (files) => {
        setTogFiles(files)
    }

    const Reset = () => {
        setIsSpinner(true)
        setTogFormData({})
        setTimeout(() => {
            setStage(1)
            setIsSpinner(true)
        }, 1000);
    }


    return (
        <>
            {isSpinner ? <Spinner title="Vennligst vent..." /> :
                <div className="form-container">
                    <br />
                    <h3 className='medium-header'>Melde skade Norske Tog Forsikring</h3>
                    <form ref={togForm} name="togForm" onSubmit={(event) => { handleTogFormSubmit(event) }} noValidate >
                        {stage === 1 && <TogEmail validCheck={validCheck} togFormData={togFormData} updateTogFormData={updateTogFormData} />}
                        {stage === 2 && <TogSms updateTogFormData={updateTogFormData} mobileNumber={mobileNumber} validCheck={validCheck} />}
                        {stage === 3 && <TogInsuranceForm togFormData={togFormData} updateTogFormData={updateTogFormData} validCheck={validCheck} radioRequired={radioRequired} updateTogFiles={updateTogFiles} />}
                        {stage === 3 && <button type="submit" className='submit-button margin-top__medium padding-small'>Send inn</button>}
                    </form>
                    {error !== "" && <p className="error-message">{error}</p>}
                    {(stage === 1 || stage === 2) && <button className='submit-button margin-top__medium padding-small' onClick={event => handleNext(event)}>Neste</button>}
                    {stage === 4 && <h4>Takk. Vi har mottat din henvendelse.</h4>}
                    {stage === 5 && <><h4>Feil!</h4>{error !== "" && <p className="error-message">{error}</p>}</>}
                    <br />
                    <p className="margin-top__large">Ved spørsmål, ta kontakt på telefonnummer <a href="tel:67552500" aria-label="Telefonnummer">67552500</a></p>
                    <br />
                    {stage !== 1 && <button className="resetButton" onClick={Reset}>Start på nytt</button>}
                </div>
            }
        </>
    )

}