import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InfoContext } from '../context';
import LyHealthForm from './LyHealthForm';

export default function LyHealth() {
    const { setCurrentCase } = useContext(InfoContext);

    let { id } = useParams();
    useEffect(() => {
        setCurrentCase(id);
    }, [])

    return (
        <>
            <LyHealthForm id={id} />
        </>
    )
}