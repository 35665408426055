import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Fail from '../src/components/Fail';
import Home from '../src/components/Home';
import ShowMessage from './components/ShowMessage';
import Upload from './components/Upload';
import Complete from './components/Complete';
import Contact from './components/Contact';
import TogHomePage from './components/Tog/TogHomePage';
import NewCase from './components/NewCase';
import LyHealth from './components/LyHealth/LyHealth';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([{
  path: "/",
  element: <App />,
  errorElement: <Fail />,
  children: [
    {
      index: true,
      element: <Home />,
    },
    {
      path: "/melding/:id",
      element: <ShowMessage />,
    },
    {
      path: "/upload/:id",
      element: <Upload />,
    },
    {
      path: "/ferdig",
      element: <Complete />,
    },
    {
      path: "/kontakt",
      element: <Contact />,
    },
    {
      path: "/ny-sak",
      element: <NewCase />,
    },
    // {
    //   path: "/fullmakt/:id",
    //   element: <Attorny />,
    // },
    {
      path: "/ly-helse/:id",
      element: <LyHealth />,
    },
    {
      path: "/norsketog",
      element: <TogHomePage />,
    },
    {
      path: "/fail",
      element: <Fail />,
    },
    {
      path: "*",
      element: <App />
    }
  ]
}])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
reportWebVitals();
